<template>
  <component :is="link ? 'router-link' : 'button'"
    :to="link ? link : null"
    type="button"
    class="primary-button"
    :class="buttonClasses"
    @mouseenter="mouseEnter"
    @focusin="mouseEnter"
    :disabled="!enabled"
  >
    <span>{{ text }}</span>
  </component>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      default: 'Button',
    },
    appearance: {
      type: String,
      default: 'solid',
    },
    size: {
      type: String,
      default: 'medium',
    },
    active: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      buttonClasses: null,
      colorIndex: 0,
    };
  },
  watch: {
    enabled: {
      handler() {
        this.setButtonClasses();
      },
    },
  },
  methods: {
    setButtonClasses() {
      this.buttonClasses = `${this.appearance} ${this.size} ${this.enabled ? 'enabled' : 'disabled'}`;
    },
    mouseEnter() {
      // change button color every time the mouse enters
      const colors = ['#D9F1DE', '#DDD3EB', '#FFDEF2', '#FFD0D0'];
      // const randomColor = colors[Math.floor(Math.random() * colors.length)];
      // document.documentElement.style.setProperty('--colorButton', randomColor);
      // every time the function is executed, add +1 to i, and when it reaches the end of the array, start again
      document.documentElement.style.setProperty('--colorButton', colors[this.colorIndex]);
      this.colorIndex += 1;
      if (this.colorIndex === colors.length) {
        this.colorIndex = 0;
      }
    },

  },
  mounted() {
    this.setButtonClasses();
  },

};
</script>

<style lang="scss" scoped>
.primary-button {
  width: 100%;
  border-radius: calc(var(--base) * 6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 calc(var(--base) * 4);
  position: relative;
  overflow: hidden;
  span {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    // width: 100%;
  }
  &.solid {
    pointer-events: none;
    background-color: rgba(var(--bodyColor), 0.06);
    color: rgba(var(--bodyColor), 0.2);
    border: 1px solid rgba(var(--bodyColor), 0);
    &.enabled {
      pointer-events: all;
      cursor: pointer;
      background-color: rgb(var(--bodyColor));
      border: none;
      border: 2px solid rgba(var(--bodyColor), 1);
      span {
        color: rgb(var(--bodyBg));
        mix-blend-mode: difference;
      }
    }

    &:before {
      height: 100%;
      width: 0%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--colorButton);
      border-radius: 50000px;
      transition: all ease-out 0.2s;
    }
    &:hover {
      // background-color: rgba(var(--colorGreen), 0);
      color: rgba(var(--bodyColor));
      // border: 2px solid rgba(var(--bodyColor), 0.1);
      &:before {
        width: 100%;
        transition: all ease-out 0.4s;
      }
    }
  }
  &.outlined {
    background-color: transparent;
    color: rgb(var(--bodyColor));
    border: 2px solid rgb(var(--bodyColor));
    transition: all ease-out 0.3s;
    &:before {
      height: 100%;
      width: 0%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--colorButton);
      border-radius: 50000px;
      // z-index: -1;
      transition: all ease-out 0.4s;
    }
    &:hover {
      // background-color: rgba(var(--colorGreen), 0);
      color: rgba(var(--bodyColor));
      // border: 2px solid rgba(var(--bodyColor), 0.1);
      &:before {
        width: 100%;
      }
    }
  }
  &.text {
    // background-color: transparent;
    border: none;
    text-decoration: none;
    transition: all ease-out 0.3s;
    span {
      width: auto;
      color: rgb(var(--bodyColor));
      // position:relative;

      &:before {
        height: 2px;
        width: 0%;
        content: "";
        position: absolute;
        bottom: calc(var(--base) * 0 - 2px);
        left: 0;
        background-color: rgb(var(--bodyColor)); // z-index: -1;
        transition: all ease-out 0.2s;
      }
      &:hover,
      &:focus {
        &:before {
          width: 100%;
          transition: all ease-out 0.3s;
        }
      }
    }
  }
  &.small {
    height: calc(var(--base) * 7);
    font-size: calc(var(--base) * 2.25);
    line-height: calc(var(--base) * 3);
    width: 100%;
    // margin-bottom: calc(var(--base) * 2);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &.large {
    height: calc(var(--base) * 8);
    font-size: calc(var(--base) * 3);
    line-height: calc(var(--base) * 8);
    // margin-bottom: calc(var(--base) * 2);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
