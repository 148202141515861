import { render, staticRenderFns } from "./kitdigital.vue?vue&type=template&id=deb1f466&scoped=true&"
import script from "./kitdigital.vue?vue&type=script&lang=js&"
export * from "./kitdigital.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "deb1f466",
  null
  
)

export default component.exports