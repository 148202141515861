import { render, staticRenderFns } from "./AppointmentStats.vue?vue&type=template&id=154e9244&scoped=true&"
import script from "./AppointmentStats.vue?vue&type=script&lang=js&"
export * from "./AppointmentStats.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentStats.vue?vue&type=style&index=0&id=154e9244&prod&scoped=true&lang=css&"
import style1 from "./AppointmentStats.vue?vue&type=style&index=1&id=154e9244&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154e9244",
  null
  
)

export default component.exports